.pipeline-container {
  width: 100%;
  min-height: 580px;
  background-color: var(--third-color);
}

.number-title {
  color: var(--third-color);
  font-size: 80px;
  font-weight: 1000;
  text-align: center;
}
