.video-container {
    width: 100%;
    height: calc(100vh);
    position: relative;
    background-color: black;
}

#video {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    filter: brightness(0.8);
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh);
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
}

.typed-text {
    color: var(--white);
    font-size: 80px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: 700;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#desc-text {
    color: var(--white);
    font-size: 50px;
    width: 80% !important;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1024px) {
    .typed-text {
        font-size: 50px;
    }

    #desc-text {
        font-size: 30px;
        width: 90% !important;
    }
}

@media (max-width: 768px) {
    .typed-text {
        font-size: 30px;
    }

    #desc-text {
        font-size: 15px;
    }
}
