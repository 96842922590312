.integrations-container {
  min-height: 520px;
}

.orange-title {
    color: var(--third-color);
    font-size: 40px;
    font-weight: 1000;
    text-align: center;
}

.orange-subtitle {
    color: var(--third-color);
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin: 0 auto;
}

.carousel-container {
    display: block;
    margin: 0 auto;
}

/* mobile */
@media (max-width: 768px) {
    .orange-subtitle {
        width: 90%;
    }
    .carousel-container {
        width: 100%;
    }
}

.slider {
    margin: 0 20px;
    overflow: "hidden";
    padding: 2rem 0;
}

.slider img {
    width: 100%;
    border-radius: 10px;
}

.react-multi-carousel-list {
    padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
    border: none;
    background: var(--forth-color);
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    border: none;
    background: var(--third-color) !important;
}