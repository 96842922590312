.products-container {
  width: 100%;
  min-height: 580px;
  background-color: var(--third-color);
}

.blue-title {
    color: var(--main-color);
    font-size: 40px;
    font-weight: 1000;
    text-align: center;
}

/* for phones */
@media (max-width: 768px) {
    .message{
        margin-top: 0px !important;
        width: 90%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .message:hover {
        transform: scale(1.0) !important;
    }
}

.message{
    transition: transform 0.3s ease;
    --bulma-message-border-style: hidden;
    --bulma-message-body-radius: 10px;
}

.message:hover{
    transform: scale(1.1);
}

.message-body{
    background-color: var(--main-color);
    box-shadow: 0 0 5px #393939;
}

.centered-images{
    display: flex;
        align-items: center;
        /* Pour centrer verticalement */
        justify-content: center;
}

.message img {
    transition: transform 0.3s ease, margin-left 0.3s ease;
}

.message:hover img:first-child {
    transform: scale(1.1);
    margin-left: 0px !important;
}

.message:hover img:last-child {
    transform: scale(1.1);
    margin-left: 10px !important;
}

.product-title {
    color: var(--white);
    font-size: 25px;
    font-weight: 700;
}

#product-picture {
    height: 50px;
    margin-top: 30px;
    margin-left: 15px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 3px #393939;
    border-radius: 10px;
}

#product-palette {
    height: 80px;
    margin-left: -15px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 3px #393939;
    border-radius: 10px;
}

#product-index {
    height: 60px;
    margin-left: 15px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 3px #393939;
    border-radius: 10px;
}

#product-mouse {
    height: 40px;
    margin-left: -15px;
    max-width: 100%;
    max-height: 100%;
    margin-top: 20px;
}

#product-computer {
    height: 70px;
    margin-left: 15px;
    max-width: 100%;
    max-height: 100%;
}

#product-iphone {
    height: 50px;
    margin-left: -15px;
    max-width: 100%;
    max-height: 100%;
    margin-top: 20px;
    box-shadow: 0 0 3px #393939;
    border-radius: 8px;
}

#product-calendar {
    height: 70px;
    margin-left: 15px;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 3px #393939;
    border-radius: 10px;
}

#product-basket {
    height: 50px;
    margin-left: -15px;
    max-width: 100%;
    max-height: 100%;
    margin-top: 20px;
}