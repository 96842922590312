nav {
    position: fixed !important;
    margin-top: 20px;
    padding: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 65%;
    background-color: rgba(242, 242, 242, 0.5) !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;
    backdrop-filter: blur(20px);
    border-radius: 10px;
}

.navbar-item {
    color: var(--black) !important;
}

/* when not on computer, center .navbar-brand */
@media (max-width: 1024px) {
    .navbar-brand {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.navbar-logo {
    transform: scale(1.5);
}

.navbar-name {
    font-weight: 700;
    color: var(--main-color) !important;
    font-size: 25px;
    margin-left: 10px;
}

.navbar-link,
a.navbar-item {
    background-color: transparent !important;
}