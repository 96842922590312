@import "https://cdn.jsdelivr.net/npm/bulma@1.0.0/css/bulma.min.css";

html {
  --main-color: #051650;
  --secondary-color: #6b2364;
  --third-color: #e8715a;
  --fourth-color: #ffb254;
  --black: #1a1a1a;
  --white: #f2f2f2;
  background-color: var(--main-color);
}

/* body {
  overflow-x: hidden;
} */

.button.is-primary {
  background-color: var(--main-color) !important;
  /* background-image: linear-gradient(30deg, var(--main-color), var(--secondary-color)); */
  border-color: var(--main-color) !important;
  border-radius: 10px;
  color: var(--white);
}

.video-container {
  width: 100%;
  height: calc(100vh);
  position: relative;
  background-color: black;
}

#video {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  filter: brightness(0.8);
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh);
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.typed-text {
  color: var(--white);
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-weight: 700;
  /* shadow */
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

#desc-text {
  color: var(--white);
  font-size: 30px;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .typed-text {
    font-size: 30px;
  }

  #desc-text {
    font-size: 15px;
  }

}